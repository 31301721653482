.header {
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 1rem 2rem;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
}

.header-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    font-size: 1.5rem;
    font-weight: 600;
    background: linear-gradient(to right, #405DE6, #833AB4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-decoration: none;
}

.login-btn {
    background: linear-gradient(45deg, #405DE6, #833AB4);
    color: white;
    padding: 0.5rem 1.5rem;
    border-radius: 25px;
    text-decoration: none;
    font-weight: 500;
    transition: transform 0.3s ease;
}

.login-btn:hover {
    transform: translateY(-2px);
}

.header-right {
    display: flex;
    align-items: center;
    gap: 1.5rem;
}

.language-switcher {
    display: flex;
    gap: 0.5rem;
}

.language-switcher a {
    padding: 0.3rem 0.6rem;
    border-radius: 4px;
    text-decoration: none;
    color: #666;
    font-size: 0.9rem;
    transition: all 0.3s ease;
}

.language-switcher a.active {
    background: #405DE6;
    color: white;
}

.language-switcher a:hover:not(.active) {
    background: rgba(64, 93, 230, 0.1);
}
.language-switcher button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    margin: 0 5px;
    font-size: 16px;
  }
  
  .language-switcher button.active {
    font-weight: bold;
    /* або інші стилі для активної мови */
  }
  .header-right {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  
  .login-btn, .dashboard-btn {
    text-decoration: none;
    color: white;
    font-weight: 500;
    padding: 8px 15px;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .login-btn:hover, .dashboard-btn:hover {
    background-color: #f0f0f0;
  }
  
  .logout-btn {
    background-color: transparent;
    border: 1px solid #d32f2f;
    color: #d32f2f;
    padding: 8px 15px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.3s;
  }
  
  .logout-btn:hover {
    background-color: #d32f2f;
    color: white;
  }