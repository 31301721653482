.error-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 20px;
}

.error-content {
    text-align: center;
    max-width: 600px;
}

.error-code {
    font-size: 96px;
    font-weight: bold;
    color: #e74c3c;
    margin-bottom: 20px;
}

.error-content h1 {
    font-size: 32px;
    margin-bottom: 16px;
}

.back-home {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #3498db;
    color: white;
    text-decoration: none;
    border-radius: 4px;
}