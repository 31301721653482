.dashboard-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  
  .logout-btn {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
  }
  
  .logout-btn:hover {
    background-color: #d32f2f;
  }
  
  .welcome-section {
    margin-bottom: 30px;
  }
  
  .dashboard-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    margin-bottom: 30px;
  }
  
  .dashboard-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  .dashboard-card.full-width {
    grid-column: 1 / -1;
  }
  
  .dashboard-card h3 {
    margin-top: 0;
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    color: #333;
  }
  
  .info-item {
    display: flex;
    margin-bottom: 12px;
  }
  
  .info-label {
    font-weight: 500;
    width: 40%;
    color: #666;
  }
  
  .info-value {
    flex: 1;
  }
  
  .stats-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 20px;
  }
  
  .stat-item {
    text-align: center;
    background-color: #f5f5f5;
    padding: 15px;
    border-radius: 8px;
  }
  
  .stat-value {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #3f51b5;
  }
  
  .stat-label {
    color: #666;
    font-size: 14px;
  }
  
  .dashboard.loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
  }
  
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 4px solid #3f51b5;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin-bottom: 20px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .dashboard.error {
    text-align: center;
    padding: 40px;
    color: #d32f2f;
  }
  
  .dashboard.error button {
    background-color: #3f51b5;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    margin-top: 20px;
    cursor: pointer;
  }
  .role-badge {
    display: inline-block;
    background-color: #3f51b5;
    color: white;
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 4px;
    margin-right: 5px;
    text-transform: capitalize;
  }