.contact-container {
    min-height: calc(100vh - 160px - 100px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    margin-bottom: 2rem; /* Add margin at the bottom */
}

.contact-content {
    background: rgba(255, 255, 255, 0.95);
    padding: 3rem;
    border-radius: 20px;
    box-shadow: 0 8px 32px rgba(31, 38, 135, 0.15);
    max-width: 800px;
    width: 100%;
    margin: auto; /* Center the content */
}

.contact-section {
    margin-bottom: 1.5rem;
}

.contact-section h2 {
    color: #405DE6;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
}

.contact-section p {
    color: #333;
    line-height: 1.6;
}

h1 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    background: linear-gradient(45deg, #405DE6, #833AB4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media (max-width: 768px) {
    .contact-container {
        padding: 1rem;
    }

    .contact-content {
        padding: 2rem;
    }
}