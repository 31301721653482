/* public/css/typing-animation.css */
.typing-animation {
    display: flex;
    align-items: center;
    margin: 1.5rem 0;
}

.chat-cloud {
    position: relative;
    background-color: #f1f1f1;
    border-radius: 18px;
    padding: 15px 20px;
    display: inline-block;
    max-width: 200px;
}

.chat-cloud:before {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 15px;
    border-width: 10px 10px 0;
    border-style: solid;
    border-color: #f1f1f1 transparent transparent;
}

.typing-dots {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    height: 24px;
}

.typing-dots span {
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: #555;
    border-radius: 50%;
    opacity: 0.4;
    animation: typingAnimation 1.4s infinite ease-in-out;
}

.typing-dots span:nth-child(1) {
    animation-delay: 0s;
}

.typing-dots span:nth-child(2) {
    animation-delay: 0.2s;
}

.typing-dots span:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes typingAnimation {
    0% {
        transform: scale(1);
        opacity: 0.4;
    }
    50% {
        transform: scale(1.2);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 0.4;
    }
}