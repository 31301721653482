/* Базові стилі */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body, #root, .App {
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    background-color: #f5f5f5;
}

.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.main-content {
    margin-top: 80px;
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 4rem 2rem;
    width: 100%;
}

/* Стилі для головної сторінки */
.home-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

h1 {
    font-size: 2.8rem;
    background: linear-gradient(45deg, #405DE6, #833AB4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 1rem;
    text-align: center;
}

.subtitle {
    color: #666;
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 3rem;
}

.chat-bubble {
    background: linear-gradient(45deg, #405DE6, #833AB4);
    color: white;
    padding: 1.2rem 2.5rem;
    border-radius: 25px;
    display: block;
    width: fit-content;
    margin: 0 auto 3rem;
    font-size: 1.1rem;
    animation: float 3s ease-in-out infinite;
    text-align: center;
}

@keyframes float {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
}

.features {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 2rem;
    margin-top: 2rem;
}

.feature {
    background: white;
    padding: 2rem;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    word-wrap: break-word;
    hyphens: auto;
}

.feature:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.feature h3 {
    color: #405DE6;
    margin-bottom: 1rem;
    font-size: 1.2rem;
    line-height: 1.3;
    word-wrap: break-word;
    hyphens: auto;
}

.feature p {
    color: #666;
    line-height: 1.6;
    font-size: 0.95rem;
}

/* Медіа-запити */
@media (max-width: 1200px) {
    .features {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .container {
        padding: 2rem 1rem;
    }

    h1 {
        font-size: 2rem;
    }

    .subtitle {
        font-size: 1rem;
    }

    .features {
        grid-template-columns: 1fr;
    }

    .feature {
        padding: 1.5rem;
    }
}
.chat-animation-container {
    display: flex;
    justify-content: center;
    margin: 0.5rem 0;
}

.typing-animation {
    margin: 0;
}