.footer {
    background: #1a1a1a;
    color: white;
    padding: 1.5rem 0;
    margin-top: auto; /* Це важливо для прикріплення футера внизу */
    width: 100%;
    box-sizing: border-box;
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1.5rem; /* Зменшили gap */
    padding: 0 2rem;
}

.footer-links {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer-links li {
    margin-bottom: 0.4rem; /* Зменшили margin */
}

.footer-links a {
    color: #fff;
    text-decoration: none;
    opacity: 0.8;
    transition: opacity 0.3s ease;
    font-size: 0.9rem; /* Зменшили розмір шрифту */
}

.footer-links a:hover {
    opacity: 1;
}

.footer h3 {
    color: #fff;
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 1.1rem; /* Зменшили розмір заголовків */
    font-weight: 500;
}

.footer-bottom {
    text-align: center;
    margin-top: 1.5rem;
    padding-top: 1rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-bottom p {
    margin: 0;
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.7);
}

.developer-info {
    margin-top: 0.5rem;
    font-size: 0.85rem;
    color: rgba(255, 255, 255, 0.6);
}

.developer-info a {
    color: #405DE6;
    text-decoration: none;
    transition: color 0.3s ease;
}

.developer-info a:hover {
    color: #833AB4;
}