.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
    min-height: calc(100vh - 200px); /* Врахування висоти хедера і футера */
  }
  
  .login-container {
    max-width: 400px;
    width: 100%;
    padding: 30px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .login-form-container h2 {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .login-form .form-group {
    margin-bottom: 20px;
  }
  
  .login-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
  }
  
  .login-form input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .login-form button {
    width: 100%;
    padding: 12px;
    background-color: #4285f4;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .login-form button:hover {
    background-color: #3367d6;
  }
  
  .login-form button:disabled {
    background-color: #b0bec5;
    cursor: not-allowed;
  }
  
  .error-message {
    color: #d32f2f;
    margin-bottom: 15px;
    font-size: 14px;
  }
  
  .success-message {
    color: #388e3c;
    text-align: center;
    margin: 20px 0;
  }
  
  .login-links {
    margin-top: 20px;
    text-align: center;
  }
  
  .login-links a {
    color: #4285f4;
    text-decoration: none;
  }
  
  .login-links a:hover {
    text-decoration: underline;
  }