.policy-container {
    max-width: 800px;
    margin: 2rem auto;
    padding: 2rem;
    background: white;
    border-radius: 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.policy-container h1 {
    color: #1a1a1a;
    margin-bottom: 2rem;
}

.policy-container h2 {
    color: #405DE6;
    margin-top: 2rem;
}

.policy-container p {
    color: #666;
    line-height: 1.6;
    margin-bottom: 1rem;
}

.policy-container ul {
    color: #666;
    margin-bottom: 1rem;
    padding-left: 2rem;
}

.policy-container li {
    margin-bottom: 0.5rem;
}