.booking-form {
    padding: 20px;
    text-align: center;
  }
  
  .booking-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .booking-button:hover {
    background-color: #0056b3;
  }